import ChinaOnly from '@components/common/ChinaOnly'
import FormattedSeller from '@components/common/FormattedSeller'
import SellerLink from '@components/common/SellerLink'
import TranslationText from '@components/common/TranslationText'
import { tipsBaopalsScoreUrl } from '@routing/blogRoutes'
import React, { FC, memo } from 'react'
import { FormattedMessage } from 'react-intl'
import Section from '../Section'
import ShareProductOrAddToCollection from '../ShareProductOrAddToCollection'

const ProductLocation = ({ city, state }) => {
  if (!city && !state) {
    return null
  }

  return (
    <ChinaOnly>
      <div className="product-location capitalize">
        <FormattedMessage id="product_page.seller_location" />{' '}
        {city?.en ? city.en : city.zhCn && <TranslationText texts={city} />}{' '}
        {state?.en ? state.en : state.zhCn && <TranslationText texts={state} />}
      </div>
    </ChinaOnly>
  )
}

const Seller = memo(({ id, shopName, platform }) => (
  <div id="tour-seller">
    <FormattedMessage id="product_page.seller" />
    <SellerLink className="strong text-capitalize" id={id} platform={platform}>
      {shopName?.en ? (
        <FormattedSeller name={shopName.en} />
      ) : (
        <TranslationText texts={shopName}>{text => <FormattedSeller name={text} />}</TranslationText>
      )}
    </SellerLink>
  </div>
))

//
//   const score = baopalsScore({
//     sold: product.sold,
//     shop: (product.shop && product.shop.type) || 'taobao',
//     ...ratings,
//   })
const Score = ({ score }) => (
  <>
    <FormattedMessage id="product_page.bp_score" />
    &#58;&nbsp;
    <a className="fs16 strong text-fresh" href={tipsBaopalsScoreUrl()} target="_blank">
      {`${score}/5`}
    </a>
  </>
)

const ShopSection: FC<{ id: number }> = props => {
  if (!props.city && !props.state && !props.shopName && !props.score) {
    return null
  }

  return (
    <div className="relative product-location">
      <Section
        renderLeft={
          <>
            {(props.city || props.state) && (
              <ProductLocation city={props.city} state={props.state} />
            )}
            {props.sellerId &&
              props.shopName &&
              props.productsCount > 8 && (
                <Seller id={props.sellerId} shopName={props.shopName} platform={props.platform} />
              )}
            {props.score ? <Score score={props.score} /> : null}
          </>
        }
        renderRight={<ShareProductOrAddToCollection id={props.id} />}
      />
    </div>
  )
}

export default ShopSection
