import SellerLink from '@components/common/SellerLink'
import { Platform } from '@interfaces/platform'
import { FavoriteProductSeller } from '@interfaces/seller'
import { FC, useState } from 'react'
import categories from '@components/discover/collectionCategories'
import './styles.css'
import DottedLine from '@components/common/DottedLine'
import AddToFavoritesModal from '@components/products/AddToFavorites/Modal'
import React from 'react'
import TranslationText from '@components/common/TranslationText'
import Pluralize from '@components/common/Pluralize'
import useAuth from '@hooks/useAuth'
import ImageList from '@components/common/ImageList'
import { cloudObjectToURL } from '@utils/'
import { openSignUpModal } from '@hooks/useSignUpModal'
import { Link } from 'react-router-dom'

const defaultSellerName = {
  taobao: 'Taobao',
  tmall: 'Tmall',
  jingdong: 'Express'
}

const getLikedUsers = (LikedUsers: any[], likesCount: number) => {
  if (!LikedUsers) return []
  const diff = likesCount - LikedUsers.length

  if (diff === 0) {
    return LikedUsers
  }
  if (diff > 0) {
    return LikedUsers.concat(new Array(diff).fill('').map((_, idx) => ({ id: -(1 + idx) })))
  }
}

const Info: FC<{
  seller: {
    access?: string
    category: string
    id?: number
    position?: number
    productSeller: FavoriteProductSeller
  }
  isMe: boolean
  onUpdate: any
  onDelete: any
  query?: any
  isWhatsHot?: boolean
}> = props => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [liked, setLiked] = useState<boolean>(props.isMe || props.seller.productSeller.liked)
  const [likesCount, setLikesCount] = useState<number>(props.seller.productSeller.userLikes || 0)
  const { user } = useAuth()

  const handleClick = () => {
    if (!user) {
      event.preventDefault()
      return openSignUpModal()
    }

    if (liked) {
      if (props.isMe) return setOpenEditModal(true)

      return
    }

    return setOpenCreateModal(true)
  }

  const likedUsers = getLikedUsers(props.seller.productSeller.likedUsers, likesCount)
  const currentCategory = categories.find(c => c.slug === props.seller.category)

  return (
    <>
      <div className="p-4 bd-t-light">
        <div className="d-flex justify-content-between align-items-center" styleName="info">
          <div styleName="sellerName">
            <SellerLink
              className="black-link fs16 bold text-capitalize"
              id={props.seller.productSeller.id}
              platform={props.seller.productSeller.shopType || Platform.TMALL}
              query={props.query}
            >
              <div className="fs16 bold text-capitalize" styleName="title">
                {props.seller.productSeller.titles.en ? (
                  <TranslationText texts={props.seller.productSeller.titles} />
                ) : (
                  <>
                    {defaultSellerName[props.seller.productSeller.shopType]} seller
                  </>
                )}
              </div>
            </SellerLink>
            {props.isWhatsHot && (
              <div className="text-grey fs12 text-capitalize">
                {currentCategory?.title}
              </div>
            )}
          </div>


          <div className="d-flex align-items-center">
            {props.isWhatsHot ? (
              <Link
                to={`${[]
                  .concat(props.seller.productSeller.platform === Platform.JD ? '/express/seller' : '/seller')
                  .concat(props.seller.productSeller.id)
                  .join('/')}`}
                target="_blank"
                styleName="button"
              >
                View Shop
              </Link>
              ) : (
              <div className="text-grey fs12 text-capitalize">
                {categories.find(c => c.slug === props.seller.category)?.title}
              </div>
            )}
            {props.isMe && props.seller.access === 'private' && (
              <div className="text-grey tw-ml-4 tw-mt-2"><i className="fs16 icon-private-collection" /></div>
            )}
            {props.isMe && (
              <div className="black-link cursor-pointer tw-ml-4 tw-mt-2" title="Edit Seller" onClick={() => setOpenEditModal(true)}>
                <i className="fs16 icon-edit" />
              </div>
            )}
          </div>
        </div>

        {!props.isMe && (
          <>
            <DottedLine className="tw-my-6" />

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center tw-w-9/12">
                <div styleName="icon" className="fs20 black-link" data-liked={liked} onClick={() => handleClick()}>
                  <i className="icon-like" />
                </div>
                <div className="bold tw-ml-2 tw-mr-4 border-right--light pr-3">{likesCount}</div>
                <ImageList
                  width={25}
                  height={25}
                  images={likedUsers.map(u => (u.id > 0 ? {
                    url: cloudObjectToURL(u.avatar) || BAOPALS.IMAGE_PLACEHOLDER,
                    link: `/discover/users/${u.username}/shops`,
                  } : {
                    url: BAOPALS.ANONYMOUS_PLACEHOLDER
                  }))}
                />
              </div>

              <div className="fs12">
                <Pluralize showCount={true} count={props.seller.productSeller.productsCount} singular="Item" />
              </div>
            </div>
          </>
        )}
      </div>

      {openEditModal && (
        <AddToFavoritesModal
          open={openEditModal}
          onCloseModal={() => setOpenEditModal(false)}
          sellerId={props.seller.id}
          private={props.seller.access === 'private'}
          category={props.seller.category}
          action='edit'
          onUpdate={props.onUpdate}
          onDelete={props.onDelete}
        />
      )}

      {openCreateModal && (
        <AddToFavoritesModal
          open={openCreateModal}
          onCloseModal={() => setOpenCreateModal(false)}
          publicSeller={props.seller.productSeller.id}
          platform={props.seller.productSeller.shopType}
          category={props.seller.category}
          setIsFavoriteSeller={() => {
            setLiked(true)
            setLikesCount(likesCount + 1)
          }}
        />
      )}
    </>
  )
}

export default Info
