// <Pluralize count={2} singular="person" plural="people" />
// <Pluralize count={1} singular="review" />

interface PluralizeProps {
  singular: string
  plural?: string

  count: number

  showCount?: boolean
  className?: string
}

const Pluralize = props => {
  const text = (() => {
    if (props.count !== 1) {
      if (props.plural === 's') {
        return `${props.singular}${props.plural}`
      }

      return props.plural
    }

    return props.singular
  })()

  return (
    <>
      <span className={props.showCount ? '' : 'd-none'}>{props.count} </span>
      {text}
    </>
  )
}

Pluralize.defaultProps = {
  className: '',
  plural: 's',
  showCount: false,
}

export default Pluralize
