import React, { useEffect, useState } from "react";
import useAuth from '@hooks/useAuth'
import { addHours, differenceInMilliseconds } from 'date-fns'
import { formatCountdown } from '@utils/dateFormat'
import { Link } from 'react-router-dom'
import Discount from './Discount'
import './styles.css'
import { useSetAtom } from "jotai";
import { discountNavAtom } from "@atoms/discount-nav";

const DiscountBanner = () => {
  const { user } = useAuth()
  const couponBannerStartAt = localStorage.getItem('couponBannerStartAt')
  const expiredAt = addHours(new Date(couponBannerStartAt), 24)
  const restTime = differenceInMilliseconds(expiredAt, new Date())

  const [show, setShow] = useState(true)

  const [countdown, setCountdown] = useState({
    hour: '00',
    minute: '00',
    second: '00'
  })

  useEffect(() => {
    const myInterval = setInterval(() => {
      setCountdown(formatCountdown(expiredAt))
    }, 1000)

    return () => clearInterval(myInterval)
  }, [couponBannerStartAt])

  const hourArr = countdown.hour.split('')
  const minArr = countdown.minute.split('')
  const secArr = countdown.second.split('')

  const renderCountdown = (
    <div className="d-flex align-items-center">
      {hourArr.map((h, i) => (
        <div key={i} styleName="countdownItem">{h}</div>
      ))} : {minArr.map((m, i) => (
        <div key={i} styleName="countdownItem">{m}</div>
      ))} : {secArr.map((s, i) => (
        <div key={i} styleName="countdownItem">{s}</div>
      ))}
    </div>
  )

  const isShow = !(!show || restTime < 1000 || user)
  const update = useSetAtom(discountNavAtom)

  useEffect(() => {
    const $content = document.querySelector('#content')
    const $mobileMenu = document.querySelector('#mobile-menu')
    const $globalNavbar = document.querySelector('#global-navbar')
    $content.setAttribute("data-show_coupon_banner", `${isShow}`);
    $mobileMenu?.setAttribute("data-show_coupon_banner", `${isShow}`);
    $globalNavbar?.setAttribute("data-show_coupon_banner", `${isShow}`);

    update(isShow)


    if (isShow) {
      document.querySelector('#burger-menu-container').classList.add('with-discount-banner')
    } else {
      document.querySelector('#burger-menu-container').classList.remove('with-discount-banner')
    }
  }, [isShow, document.querySelector('#global-navbar')])

  if (!isShow) { return null }

  return (
    <div className="bg-orange relative">
      <Link to="/signup" className="container" styleName="wrapper">
        <Discount />
        <div className="d-flex align-items-center" styleName="countdown">
          <div className="tw-mr-2 black-text">Expires in</div> {renderCountdown}
        </div>
      </Link>

      <i styleName="close" className="icon-close cursor-pointer tw-ml-2" onClick={() => setShow(false)} />
    </div>
  )
}

export default DiscountBanner
